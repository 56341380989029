
export default {
  name: 'DropdownListItems',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    navigatedItemIndex: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      search: '',
      showOptions: false,
    }
  },
  methods: {
    onSelect(value) {
      this.$emit('select', value)
    },
  },
}
